import * as React from "react";
import { Helmet } from "react-helmet";

import { useConfig } from "gatsby-theme-advanced";

import './css/styles.css'

import Layout from "../layouts";

const AboutPage = (): JSX.Element => {
  const config = useConfig();

  return (
    <Layout>
      <div className="centered-container px-8">
        <Helmet title={`About | ${config.website.title}`} />
        <h1>Hello</h1>
        <p>My name is Mark Thomas.I have fallen on hard times recently and have decided to use my misfortune as an opportunity to document my trials and tribulations, as they lay in front of me. I hope that my escaping from my situation of homelessness can serve as a beacon of hope for people who need help.</p>
      </div>
    </Layout>
  );
};

export default AboutPage;
